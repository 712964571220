import React from 'react';
import { Navbar, Why } from '../components';
import Footer from '../k-components/Footer/Footer';



import './soon.css';

const Soon = () => {
  return (
    <div className="App">
      <div>
        <Navbar />
        <Why />
      </div>

    </div>
  )
}

export default Soon